// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
const images = require.context('../images', true)
const fonts = require.context('../fonts', true)

// const imagePath = (name) => images(name, true)

import Rails from "@rails/ujs"
import "@hotwired/turbo-rails"
import "channels"
import '../stylesheets/application.scss';
import 'chart.js/dist/chart.min.js';
import 'notyf/notyf.min.css';
import { Notyf } from 'notyf';
import "@fortawesome/fontawesome-free/css/all"
import flatpickr from "flatpickr";
import 'flatpickr/dist/flatpickr.min.css';
import "chartkick/chart.js"
import Choices from 'choices.js';
import LocalTime from "local-time"
import 'cropperjs/dist/cropper.css';
import Cropper from 'cropperjs/dist/cropper.js';
import Swiper, { Navigation, Pagination, Keyboard } from 'swiper';

// configure Swiper to use modules
Swiper.use([Navigation, Pagination, Keyboard]);
import 'swiper/swiper-bundle.css';

window.Swiper = Swiper;

setTimeout(function(){
  window.notyf = new Notyf({ position: {
    x: 'center',
    y: 'top',
  }});
}, 1000);
window.Choices = Choices;


Rails.start()
import "controllers"

window.Rails = Rails;
window.Cropper = Cropper;

LocalTime.start()